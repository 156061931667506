import React from "react"
import { graphql } from 'gatsby';

import PostListTemplate from './post_list_layout'

const PostCatsQuery = function ({ data: { allSitePage: { edges }} }) {
  // console.log(edges)
  return (
    <PostListTemplate list={edges[0].node.context.list} tag={edges[0].node.context.tag} />
  )
}

export const pageQuery = graphql`
query ($tag: String!) {
  allSitePage(filter: {context: {tag: {eq: $tag}}}) {
    edges {
      node {
        id
        context {
          list {
            slug
            title
            date
            excerpt
          }
          tag
        }
      }
    }
  }
}
`

export default PostCatsQuery
